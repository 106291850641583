import client from "@/api/client"
import { BillingCycle, Chain, CryptoType, ProductType, SubscriptionType } from "@/api/sdk"
import { networksByChains } from "@/constants"
import { useAuthenticatedQuery, useToast } from "@/hooks"
import { currency } from "@/utils/currency"
import { useMutation } from "@tanstack/react-query"
import { useEffect, useMemo, useState } from "react"
import {
  useAccount,
  useBalance,
  useBlockNumber,
  useWriteContract,
  useSimulateContract,
  useSendTransaction,
  useSwitchChain,
} from "wagmi"
import { mainnet, sepolia } from "wagmi/chains"
import { USING_TEST_NET } from "@/config"
import Modal from "../Modal"
import IconButton from "../IconButton"
import Selector from "../Selector"

interface CryptoPaymentProps {
  cryptoPayment: {
    productType: ProductType
    productId: string
    workspaceId?: string
    subscriptionType?: SubscriptionType
    billingCycle?: BillingCycle
    seatCount?: number
    addSeatEmails?: string[]
  }
  onClose: () => void
  onSuccessfulPayment?: () => void
}

const CryptoPaymentModal = ({ cryptoPayment, onClose, onSuccessfulPayment }: CryptoPaymentProps) => {
  const { address, chain } = useAccount()
  const { data: balanceResult } = useBalance({ chainId: chain?.id, address: address })

  const { switchChain, isPending: isSwitchingNetwork } = useSwitchChain({
    mutation: {
      onError: (e: any) => {
        toast({
          status: "error",
          title: e?.shortMessage ?? "Something went wrong. Please try again later.",
        })
      },
    },
  })
  const { data: blockNumber } = useBlockNumber()

  const networks = USING_TEST_NET ? networksByChains.testnet : networksByChains.mainnet
  const initialNetwork = USING_TEST_NET ? sepolia.id : mainnet.id
  const initialToken = CryptoType.USDT

  const [state, setState] = useState<{ network: number; token: CryptoType }>({
    network: initialNetwork,
    token: initialToken,
  })

  const onNetworkChange = (chainId: number) => {
    setState({
      network: chainId,
      token: initialToken,
    })
  }

  const onTokenChange = (token: CryptoType) => {
    setState({
      ...state,
      token,
    })
  }

  const { data: preTxData, isLoading: isLoadingPreTx } = useAuthenticatedQuery(
    ["preorder", cryptoPayment, state.network, state.token, address],
    () =>
      client.api
        .paymentControllerPrePurchasePackageByCrypto({
          chain: state.network as Chain,
          currency: state.token,
          address: address!,
          productType: cryptoPayment.productType,
          productId: cryptoPayment.productId,
          // for subscription
          workspaceId: cryptoPayment.workspaceId,
          subscriptionType: cryptoPayment.subscriptionType,
          billingCycle: cryptoPayment.billingCycle,
          seatCount: cryptoPayment.seatCount,
          // for subscription
        })
        .then(res => res.data),
    {
      enabled: !!cryptoPayment && !!address,
      refetchOnWindowFocus: false,
    },
  )

  const [customError, setCustomError] = useState<string | null>(null)

  useEffect(() => {
    if (networks[state.network].tokens[state.token].abi === undefined) {
      if (
        balanceResult?.value !== undefined &&
        preTxData?.value !== undefined &&
        balanceResult.value < BigInt(preTxData.value)
      ) {
        setCustomError("Insufficient balance.")
      } else {
        setCustomError("")
      }
    } else {
      setCustomError(null)
    }
  }, [state.network, state.token, balanceResult?.value, preTxData?.value, networks])

  const wrongChain = chain?.id !== state.network

  const chainName = networks[state?.network]?.name

  const toast = useToast()

  const {
    data,
    error,
    isLoading: isPreparing,
  } = useSimulateContract({
    chainId: state.network,
    address: preTxData?.tokenAddress as `0x${string}`,
    abi: networks[state.network].tokens[state.token].abi,
    functionName: "transfer",
    args: [preTxData?.vaultAddress as `0x${string}`, BigInt(preTxData?.value ?? "0")],
    query: {
      enabled: !wrongChain,
    },
  })

  const errorMessage = useMemo(() => {
    if ((error as any)?.message?.includes("ERC20InsufficientBalance")) return "Insufficient balance."

    if ((error as any)?.shortMessage?.includes("transfer amount exceeds balance")) return "Insufficient balance."

    // if (
    //   (error as any)?.shortMessage?.includes("Execution reverted for an unknown reason.") &&
    //   preTxData?.tokenAddress === networksByChains.mainnet[1].tokens.WETH.address
    // )
    //   return "Insufficient balance."

    if ((error as any)?.shortMessage?.includes("Execution reverted for an unknown reason.")) return "Unknown error."

    // handle USDT error exception
    if ((error as any)?.shortMessage?.includes("Missing or invalid parameters") && state.token === CryptoType.USDT) {
      return "Insufficient balance."
    }

    return ""
  }, [error, preTxData?.tokenAddress, state.token])

  const { isPending: isWritingTransfer, writeContract } = useWriteContract()

  const { sendTransaction, isPending: isSendingTx } = useSendTransaction({
    mutation: {
      onSuccess: hash => {
        mutateConfirmTransaction(hash)
      },
      onError: (e: any) => {
        toast({
          status: "error",
          title: e?.shortMessage ?? "Something went wrong. Please try again later.",
        })
      },
    },
  })

  const { mutate: mutateConfirmTransaction, isPending: isConfirmingOrder } = useMutation({
    mutationFn: (hash: string) => {
      return client.api.paymentControllerPurchasePackageByCrypto({
        address: address!,
        currency: state.token,
        sendAtBlock: blockNumber!.toString(),
        productType: cryptoPayment.productType,
        productId: cryptoPayment.productId,
        // for subscription
        workspaceId: cryptoPayment.workspaceId,
        subscriptionType: cryptoPayment.subscriptionType,
        billingCycle: cryptoPayment.billingCycle,
        seatCount: cryptoPayment.seatCount,
        addSeatEmails: cryptoPayment.addSeatEmails,
        // for subscription
        txHash: hash,
        chain: state.network as Chain,
        preTransactionId: preTxData?.preTransactionId!,
      })
    },
    onSuccess: () => {
      toast({
        status: "success",
        title: "Successfully paid. Please wait for awhile before the item is delivered to your account.",
      })
      onSuccessfulPayment?.()
      onClose()
      // qc.invalidateQueries(["get-data-shop-item-banner"])
      // qc.invalidateQueries(["get-data-item-type", ShopItemType.Ram])
      // setCryptoPayment(null)
    },
    onSettled: () => {
      // qc.invalidateQueries(["shop"])
      // qc.invalidateQueries(["guest-shop"])
    },
  })

  const isLoading =
    isWritingTransfer || isConfirmingOrder || isSendingTx || isLoadingPreTx || isSwitchingNetwork || isPreparing

  const finalErrorMessage = errorMessage || customError

  const isDisabled = isLoading || !blockNumber || !address || !!finalErrorMessage // || wrongChain

  const handlePay = () => {
    if (!cryptoPayment) return

    if (isDisabled) return

    if (wrongChain) {
      toast({
        status: "error",
        title: `Please switch to ${chainName} network.`,
      })

      return
    }

    if (state.token === CryptoType.ETH) {
      sendTransaction({
        to: preTxData?.vaultAddress as `0x${string}`,
        value: BigInt(preTxData?.value ?? "0"),
      })
    } else {
      if (data?.request) {
        writeContract?.(data.request, {
          onSuccess: hash => {
            mutateConfirmTransaction(hash)
          },
          onError: (e: any) => {
            toast({
              status: "error",
              title: e?.shortMessage ?? "Something went wrong. Please try again later.",
            })
          },
        })
      }
    }
  }

  const maximumFractionDigits = [CryptoType.USDT, CryptoType.USDC, CryptoType.USDCE].includes(state.token) ? 2 : 5

  return (
    <Modal title="CRYPTO PAYMENT" className="overflow-visible max-w-sm" isOpen={!!cryptoPayment} onClose={onClose}>
      <form className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <label className="block font-semibold text-atherGray-300 text-sm">Blockchain network</label>
          <Selector
            placement="bottom"
            className="bg-atherGray-800 h-7 pl-2 py-1 text-atherGray-100"
            listClassName="bg-atherGray-700"
            value={networks[state.network].name ?? "Select category"}
            options={
              Object.keys(networks).map(chainId => ({ id: chainId, name: networks[parseInt(chainId)].name })) ?? []
            }
            onSelect={v => {
              onNetworkChange(parseInt(v.id))
            }}
          />
          {wrongChain && (
            <p
              className="font-semibold text-atherGray-0 text-xs cursor-pointer text-red-500 underline"
              onClick={() => {
                switchChain?.({ chainId: state.network })
              }}
            >
              Switch to {chainName}
            </p>
          )}
        </div>
        <div className="flex flex-col gap-1">
          <label className="block font-semibold text-atherGray-300 text-sm">Token</label>
          <Selector
            placement="bottom"
            className="bg-atherGray-800 h-7 pl-2 py-1 text-atherGray-100"
            listClassName="bg-atherGray-700"
            value={state.token}
            options={Object.keys(networks[state.network].tokens).map(token => ({ id: token, name: token })) ?? []}
            onSelect={v => {
              onTokenChange(v.id as CryptoType)
            }}
          />
          {/* {fieldState.error && <p className="text-red-500 text-xs">{fieldState.error.message}</p>} */}
        </div>
        <div className="flex flex-col gap-1">
          <label className="block font-semibold text-atherGray-300 text-sm">Sender address</label>
          <div>{address ? `${address?.slice(0, 6)}...${address?.slice(-4)}` : "Not connected"}</div>
        </div>
        <div className="flex flex-col gap-1">
          <label className="block font-semibold text-atherGray-300 text-sm">Price</label>
          <div>
            {preTxData
              ? `${currency(parseFloat(preTxData.value) / parseFloat(preTxData.decimal), {
                  maximumFractionDigits: maximumFractionDigits,
                })} ${preTxData.symbol}`
              : 0}
          </div>
        </div>

        {finalErrorMessage && (
          <div className="flex flex-col gap-1">
            <p className="text-red-500 text-xs">{finalErrorMessage}</p>
          </div>
        )}

        <div className="flex flex-col gap-1">
          <IconButton className="text-xs" isLoading={isLoading} onClick={handlePay} disabled={isDisabled}>
            PAY
          </IconButton>
        </div>
      </form>
    </Modal>
  )
}

export default CryptoPaymentModal
