import Modal from "@/components/Modal"
import { payment2C2PUrl, xsollaUrl } from "@/constants"
import { googleAnalytics } from "@/lib/gtag"
import { useGetListSubscriptionQuery, useUserWorkspaceDetailQuery } from "@/queries"
import { useQueryClient } from "@tanstack/react-query"
import { useRouter } from "next/router"
import React, { useEffect, useRef, useState } from "react"

interface Payment2C2PModalProps {
  isOpen: string | null
  onClose: () => void
  onSuccess?: () => void
}

const Payment2C2PModal = ({ isOpen, onClose, onSuccess }: Payment2C2PModalProps) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const idTimer = useRef<NodeJS.Timeout | null>(null)
  const currentPaymentInfo = useRef({})
  const router = useRouter()
  const qc = useQueryClient()
  const [status, setStatus] = useState("")

  const handleClose = () => {
    setStatus("")
    idTimer.current = null
    iframeRef.current = null
    currentPaymentInfo.current = {}
    onClose()
  }

  useEffect(() => {
    const handleRefresh = () => {
      const listSubscriptionKey = useGetListSubscriptionQuery.getKey()
      const userInfoKey = ["user-info"]
      const detailWorkspaceKey = useUserWorkspaceDetailQuery.getKey()

      qc.invalidateQueries({ queryKey: listSubscriptionKey })
      qc.invalidateQueries({ queryKey: userInfoKey })
      qc.invalidateQueries({ queryKey: detailWorkspaceKey })
    }

    const handlePostMessage = event => {
      if (event.origin !== xsollaUrl && event.origin !== payment2C2PUrl) {
        return
      }

      if (event.origin === payment2C2PUrl) {
        const eventData = event.data.paymentResult

        const responseCode = eventData?.respCode as string

        setStatus(responseCode)

        if (responseCode === "0003") {
          idTimer.current = setTimeout(() => {
            handleClose()
          }, 5000)
        } else if (responseCode === "2000") {
          idTimer.current = setTimeout(() => {
            handleClose()
          }, 5000)
        }
      }

      if (event.origin === xsollaUrl) {
        const eventData = JSON.parse(event.data)
        if (eventData?.command.includes("open-payment")) {
          currentPaymentInfo.current = eventData?.data ?? {}
        }

        if (eventData?.data?.action === "open-paystation") {
          idTimer.current = setTimeout(
            () => {
              handleClose()
              // refresh payment status
              handleRefresh()
              googleAnalytics.event({
                action: "view",
                category: "payment",
                label: "expired_payment",
                params: { ...currentPaymentInfo.current, status: "expired" },
              })
            },
            5 * 60 * 1000,
          )
        }

        if (eventData?.data?.paymentInfo?.status === "done" || eventData?.data?.paymentInfo?.status === "troubled") {
          // refresh payment status
          handleRefresh()
        }

        if (eventData?.data?.paymentInfo?.status === "done") {
          handleClose()

          googleAnalytics.event({
            action: "view",
            category: "payment",
            label: "success_payment",
            params: { ...currentPaymentInfo.current, status: "success" },
          })

          if (onSuccess) {
            onSuccess()
            return
          }

          router.replace({
            query: {
              status: "done",
            },
          })
        }
      }
    }

    window.addEventListener("message", handlePostMessage)

    return () => {
      window.removeEventListener("message", handlePostMessage)
    }
  }, [qc]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isOpen && idTimer.current) {
      clearTimeout(idTimer.current)
    }
  }, [isOpen])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (status === "2000" || status === "0003") {
        const id = iframeRef.current?.contentWindow?.document?.getElementById("backToMerchantForm-btn")

        if (id) {
          id.addEventListener("click", () => {
            handleClose()
          })
        }
      }
    }, 1500)

    return () => {
      clearTimeout(timer)
    }
  }, [status])

  return (
    <Modal
      isOpen={!!isOpen}
      onClose={handleClose}
      className="w-full max-w-screen-2xl h-full bg-white"
      closeButtonClassName="bg-atherGray-850"
    >
      <iframe
        ref={iframeRef}
        title="Xsolla Payment"
        loading="lazy"
        style={{
          width: "100%",
          height: "100%",
        }}
        src={isOpen ?? ""}
      />
    </Modal>
  )
}

export default Payment2C2PModal
