import { ArrowDownIcon } from "@/components/shared/icons"
import Accordion from "@/components/Workspace/FoldersV2/Accordion"
import { cn } from "@/lib/utils"
import React, { useState } from "react"

const AccordionQuestion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Accordion
      className="py-2 px-4 bg-atherGray-900 rounded-2xl"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      hiddenButton
      title={title}
      titleClassName="text-atherGray-100 text-sm"
      action={
        <div className="flex items-center">
          <span
            className={cn("transition-transform ml-2", {
              "-rotate-90": !isOpen,
            })}
          >
            <ArrowDownIcon width={16} height={16} />
          </span>
        </div>
      }
    >
      {() => <div className="text-sm text-atherGray-300">{children}</div>}
    </Accordion>
  )
}

const FrequentlyAskedQuestions = () => {
  return (
    <div className="mt-8">
      <h3 className="text-xl font-semibold text-center mb-4">Frequently Asked Questions</h3>
      <div className="space-y-2">
        <AccordionQuestion title="Can I use the work I create or other people’s work on GAIA for commercial purposes?">
          You retain the intellectual property rights of the content generated by you on GAIA, and we do not prohibit
          you from using your own or others&apos; work for commercial purposes. However, it is important to note that
          when using others&apos; work for commercial purposes, you need to obtain authorization from the respective
          owners. We are not responsible for any risks associated with commercial use, and you need to assume the
          relevant risks and responsibilities that may arise from such usage.
        </AccordionQuestion>
        <AccordionQuestion title="What payment methods are supported on GAIA?">
          We currently support 2 payment methods: fiat currency via the 2C2P global payment gateway, and cryptocurrency
          (USDT, USDC) via Ethereum, OP, and Polygon networks.
        </AccordionQuestion>
        <AccordionQuestion title="Where can I manage my subscription plan at?">
          {`Manage your subscription plan at “Plan & Billing” >> “Billing”.`}
        </AccordionQuestion>
        <AccordionQuestion title="Can I receive an invoice for my purchase?">
          If you require an invoice, you can export it from the “Billing” page. You can also check the date, amount
          purchased as well as the payment method and plan of each transaction.
        </AccordionQuestion>
        <AccordionQuestion title="When will my subscription plan expire?">
          The next billing date will be on the same date of the following month that you purchased the plan. <br /> For
          example, if you purchase the plan on 5 January 2024, the next billing date will be on 5 February 2024. If you
          make the purchase on 31 May 2024, the next billing date will be on the last day of the following month, which
          is 30 June 2024.
        </AccordionQuestion>
        <AccordionQuestion title="Will my plan be automatically renewed after my current billing cycle ends?">
          It depends on the payment method you set on GAIA:
          <br />
          - For cryptos (USDT, USDC): You will receive a payment notification on GAIA platform and via your registered
          email address to manually complete the payment to keep the plan active.
          <br />- For 2C2P payment gateway: The payment will the processed automatically through the card that you used
          to purchase the previous billing cycle. You will receive an invoice via your email and on GAIA once the
          payment is successful.
        </AccordionQuestion>
        <AccordionQuestion title="What should I do if my payment was unsuccessful?">
          Payments may fail for a variety of reasons. If you&apos;re having trouble making a payment, try these
          troubleshooting steps first:
          <br />
          - Double-check that all your payment information was entered correctly.
          <br />
          - Make sure you have sufficient funds in your account.
          <br />
          - Try to make your payment with another method that we support, if you have one available.
          <br />
          If you still cannot pay for your subscription, please don’t hesitate to contact us at hello@protogaia.com for
          assistance from our team.
        </AccordionQuestion>
        <AccordionQuestion title="How can I change my payment method?">
          -To change from cryptos to 2C2P payment method: wait until the end of your billing cycle, and simply change
          the payment method to 2C2P to renew your plan.
          <br />- To change from 2C2P to cryptos: You will need to cancel the function of auto-process via 2C2P by
          clicking on “Auto-renew” button. Then, change to crypto payment method when you receive the payment
          notification for the next billing cycle from GAIA.
        </AccordionQuestion>
        <AccordionQuestion title="How to update payment details if I use 2C2P for payment?">
          Click on &quot;Change payment info&quot; and input the details for the new card you wish to use for purchases.
        </AccordionQuestion>
        <AccordionQuestion title="How can I upgrade my subscription plan?">
          You can upgrade your plan at any time. Your subscription will be reset immediately and you can instantly enjoy
          all of the exclusive features. The next renewal will be 1 month (or 1 year for yearly plan) from your upgrade
          date.
        </AccordionQuestion>
        <AccordionQuestion title="Can I downgrade my subscription plan?">
          You can change to a lower-tier plan. If so, the change will start on your next payment date. Until then, you
          can still use all the features of your current plan.
          <br />
          Please not that downgrading your plan will not result in a refund for your current plan.
        </AccordionQuestion>
        <AccordionQuestion title="How can I cancel my subscription plan?">
          If you wish to cancel your subscription plan, please go to xx to cancel at any time. Cancellations are
          effective at the end of your current billing cycle, and all subscription benefits will still be available
          until then.
        </AccordionQuestion>
        <AccordionQuestion title="Can I request a refund?">
          Unfortunately we do not offer refunds on any purchases made on GAIA. Your account will remain active until the
          end of the current billing period, even if it is cancelled. We encourage all our valued users to carefully
          review the package details before making payment. If you have any inquiries regarding our offerings, please
          refer to our FAQ and don’t hesitate to contact our team for more information.
        </AccordionQuestion>
        <AccordionQuestion title="Will my CREDITS be expired?">
          The credit balance of the subscription and of the credit package are recorded separately.
          <br />
          For subscription, CREDITS will not be expired as long as your subscription is not cancelled. If cancelled,
          your CREDITS will be inactive in 15 days from the cancellation date.
          <br />
          For credit package, the CREDITS will not be expired.
        </AccordionQuestion>

        <AccordionQuestion title="What are GAIA’s generation CREDIT cost rules?">
          The CREDITS amount used will vary depending on how complex the task is.
          <br />
          Normally, 1 fast task takes about 15-20 CREDITS
        </AccordionQuestion>
      </div>
    </div>
  )
}

export default FrequentlyAskedQuestions
