import { CurrencyCode, PaymentMethod, ProductType } from "@/api/sdk"
import LoadingLogo from "@/components/LoadingLogo"
import CryptoPaymentModal from "@/components/ModalsPortal/CryptoPaymentModal"
import { clientUrl } from "@/constants"
import { useAuth } from "@/providers/authContext"
import { useGetListSubscriptionQuery, useGetPaymentPackagesQuery, usePaymentPakagesMutate } from "@/queries"
import { useWeb3Modal } from "@web3modal/wagmi/react"
import { useCallback, useRef, useState } from "react"
import { useAccount } from "wagmi"
import { paymentMethods } from "../UpgradeAccount"
import CustomPlan from "./CustomPlan"
import dynamic from "next/dynamic"
import { useSetting } from "../SettingProvider"
import PlanTabs from "./PlanTabs"
import Subscription from "./Subscription"
import Credits from "./Credits"
import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions"
import SelectPaymentMethodsModal from "./SelectPaymentMethodsModal"

const Payment2C2PModal = dynamic(() => import("./Payment2C2PModal"), { ssr: false })

export const subscriptionTabs = [
  {
    id: "Monthly",
  },
  {
    id: "Bi-Yearly",
    sale: "10%",
  },
  {
    id: "Yearly",
    sale: "30%",
  },
]

interface PlansUIProps {
  isModal?: boolean
}

const PlansUI = ({ isModal = false }: PlansUIProps) => {
  const { user, userInfoQuery } = useAuth()
  const selectedRef = useRef<string | null>(null)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(paymentMethods[0].id)
  const [isOpenSelectPaymentMethod, setIsOpenSelectPaymentMethod] = useState(false)
  const [cryptoPayment, setCryptoPayment] = useState<{ productType: ProductType; productId: string } | null>(null)
  const { isConnected } = useAccount()
  const { open } = useWeb3Modal()
  const { currentWorkspaceDetail, userPlan, isLoadingCurrentWorkspace, refetchCurrentWorkspace, refetchUserPlan } =
    useSetting()

  const { mutate: mutatePaymentPackages, isPending } = usePaymentPakagesMutate({
    onSuccess: data => {
      selectedRef.current = null
      setPaymentUrl(data.url)
      setIsOpenSelectPaymentMethod(false)
    },
  })

  const { data: paymentPackages, isLoading: isLoadingPackages } = useGetPaymentPackagesQuery()

  const { data: listSubscription = [], isLoading: isLoadingSubscriptions } = useGetListSubscriptionQuery()

  const [paymentUrl, setPaymentUrl] = useState<string | null>(null)

  const handleProcessPayment = useCallback(async () => {
    switch (selectedPaymentMethod) {
      case PaymentMethod.Value2C2P:
        // 2p2c payment
        mutatePaymentPackages({
          data: {
            creditPackageId: selectedRef.current!,
            currencyCode: CurrencyCode.VND,
            frontendReturnUrl: `${clientUrl}/settings/account?tab=Plans`,
            paymentMethod: PaymentMethod.Value2C2P,
          },
          userUid: user?.uid ?? "",
        })
        break
      case PaymentMethod.XSOLLA:
        // xsolla payment
        mutatePaymentPackages({
          data: {
            creditPackageId: selectedRef.current!,
            currencyCode: CurrencyCode.VND,
            frontendReturnUrl: `${clientUrl}/settings/account?tab=Plans`,
            paymentMethod: PaymentMethod.XSOLLA,
          },
          userUid: user?.uid ?? "",
        })
        break
      case PaymentMethod.CRYPTO:
        // crypto payment
        if (!isConnected) {
          open()
          return
        }

        setCryptoPayment({ productType: ProductType.Credit, productId: selectedRef.current! })
        break
    }
  }, [selectedPaymentMethod, isConnected, open, mutatePaymentPackages, user?.uid])

  if (isLoadingPackages || isLoadingSubscriptions)
    return (
      <div className="flex items-center justify-center h-full flex-1 w-full">
        <LoadingLogo />
      </div>
    )

  return (
    <div className="w-full relative flex items-center flex-col">
      <div className="w-full pb-12 flex justify-center">
        <div className="w-full max-w-[40rem] mx-auto">
          <PlanTabs tabClassName={isModal ? "pt-12" : ""} isMember={currentWorkspaceDetail?.owner?.uid !== user?.uid}>
            {({ tab }) => (
              <div>
                {tab === "subscription" ? (
                  <Subscription
                    userInfoQuery={userInfoQuery}
                    refetch={() => {
                      refetchUserPlan()
                      refetchCurrentWorkspace()
                    }}
                    listSubscription={listSubscription}
                    currentWorkspaceDetail={currentWorkspaceDetail}
                    isLoadingCurrentWorkspace={isLoadingCurrentWorkspace}
                    user={user}
                    userPlan={userPlan}
                  />
                ) : tab === "credits" ? (
                  <>
                    {currentWorkspaceDetail?.owner?.uid !== user?.uid ? null : (
                      <Credits
                        isPending={isPending}
                        paymentPackages={paymentPackages}
                        selectedRef={selectedRef}
                        setIsOpenSelectPaymentMethod={setIsOpenSelectPaymentMethod}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <div className="flex items-center justify-center space-x-2 mb-8">
                      <CustomPlan />
                    </div>
                  </>
                )}
              </div>
            )}
          </PlanTabs>
          {!isModal && <FrequentlyAskedQuestions />}
        </div>
      </div>

      <SelectPaymentMethodsModal
        isOpen={isOpenSelectPaymentMethod}
        isLoading={isPending}
        selectedPaymentMethod={selectedPaymentMethod}
        setSelectedPaymentMethod={setSelectedPaymentMethod}
        handleProcessPayment={handleProcessPayment}
        onClose={() => {
          setSelectedPaymentMethod(paymentMethods[0].id), setIsOpenSelectPaymentMethod(false)
        }}
      />

      <Payment2C2PModal
        isOpen={paymentUrl}
        onClose={() => {
          setPaymentUrl(null)
        }}
      />

      {cryptoPayment && (
        <CryptoPaymentModal
          cryptoPayment={cryptoPayment}
          onClose={() => setCryptoPayment(null)}
          onSuccessfulPayment={() => setIsOpenSelectPaymentMethod(false)}
        />
      )}
    </div>
  )
}

export default PlansUI
