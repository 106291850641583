import { SubscriptionPackageItem, SubscriptionStatus, WorkspaceDetail } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import { StarFillIcon, StartIcon, TickIcon } from "@/components/shared/icons"
import { User } from "firebase/auth"
import useCustomRouter from "@/hooks/useCustomRouter"
import React from "react"
import { usePaymentSuccessStore } from "@/stores"
import classNames from "classnames"

interface PlanItemProps {
  subscription: SubscriptionPackageItem
  tab: string
  isTrial?: boolean
  currentWorkspace?: WorkspaceDetail
  user: User | null
  isLoadingCurrentWorkspace?: boolean
  isAccessFeatures?: boolean
  isActive?: boolean
  userPlanStatus?: SubscriptionStatus
  isRequestCancel?: boolean
  onUnsubscribe?: () => void
}

const PlanItem = ({
  subscription,
  tab,
  isRequestCancel,
  isActive,
  isTrial,
  onUnsubscribe,
  isAccessFeatures,
  currentWorkspace,
  user,
  isLoadingCurrentWorkspace,
  userPlanStatus,
}: PlanItemProps) => {
  const router = useCustomRouter()
  const setIsOpen = usePaymentSuccessStore(state => state.setIsOpen)

  return (
    <div
      className={classNames("bg-atherGray-900 w-full md:w-[19rem] rounded-2xl py-4 border-[2px]", {
        "border-atherPurple-500": isActive,
        "border-transparent": !isActive,
      })}
    >
      <div className="px-4">
        <p className="font-semibold mb-2">{subscription.name}</p>
        <h2 className="text-4xl">
          $
          {subscription.id !== "newbie"
            ? tab === "Monthly"
              ? subscription.monthlyCost
              : tab === "Bi-Yearly"
                ? (parseInt(subscription.biYearlyCost) / 6).toFixed(0)
                : tab === "Yearly"
                  ? (parseInt(subscription.yearlyCost) / 12).toFixed(0)
                  : "???"
            : parseInt(subscription.yearlyCost)}
        </h2>
        <p className="text-sm text-atherGray-300 mb-2">
          {subscription.id !== "newbie" ? "Monthly / per User" : "Yearly / per User"}
        </p>
        {currentWorkspace?.subscriptionPackageId === subscription.id && !isTrial ? (
          userPlanStatus === SubscriptionStatus.PastDue ? (
            <IconButton
              isLoading={isLoadingCurrentWorkspace}
              colorScheme={"yellow"}
              onClick={() => setIsOpen(true)}
              className="mb-4 w-full text-center p-2 rounded-lg text-sm font-semibold text-atherGray-950"
            >
              Renew Now!
            </IconButton>
          ) : isAccessFeatures ? (
            <div className="mb-4 text-center bg-atherGray-850 p-2 rounded-lg text-sm font-semibold text-atherGray-300">
              Current plan
            </div>
          ) : (
            <IconButton
              disabled={currentWorkspace?.owner?.uid !== user?.uid || isRequestCancel}
              isLoading={isLoadingCurrentWorkspace}
              colorScheme={"primary"}
              onClick={() =>
                router.push(
                  `/settings/upgrade-account?subscriptionId=${subscription.id}&tab=${subscription.id === "newbie" ? "Yearly" : tab}`,
                  undefined,
                  { shallow: true, scroll: true },
                )
              }
              className="mb-4 w-full text-center p-2 rounded-lg text-sm font-semibold text-atherGray-0"
            >
              Subscribe
            </IconButton>
          )
        ) : (
          <IconButton
            disabled={currentWorkspace?.owner?.uid !== user?.uid || isRequestCancel}
            isLoading={isLoadingCurrentWorkspace}
            colorScheme={"primary"}
            onClick={() => {
              if (subscription.id === "newbie" && !(isTrial || currentWorkspace?.subscriptionPackageId === "free")) {
                onUnsubscribe?.()
                return
              }
              router.push(
                `/settings/upgrade-account?subscriptionId=${subscription.id}&tab=${subscription.id === "newbie" ? "Yearly" : tab}`,
                undefined,
                { shallow: true, scroll: true },
              )
            }}
            className="mb-4 w-full text-center p-2 rounded-lg text-sm font-semibold text-atherGray-0"
          >
            {subscription.id === "newbie"
              ? isTrial
                ? "Pay Now"
                : currentWorkspace?.subscriptionPackageId === "free"
                  ? `Upgrade to ${subscription.name}`
                  : "Subscribe"
              : `Upgrade to ${subscription.name}`}
          </IconButton>
        )}
      </div>
      <div className="text-atherGray-300 text-sm space-y-2 mt-8">
        <div
          className="p-4"
          style={{
            backgroundImage: "linear-gradient(180deg, #262624 0%, rgba(38, 38, 36, 0) 100%)",
          }}
        >
          <p className="font-semibold mb-2 text-whiteAlpha-600">Special Deals</p>
          <div className="flex mb-1">
            <StarFillIcon className="text-atherPurple-100" />
            <p className="ml-2 flex-1 font-semibold">
              {subscription.extraCredits} free CREDITs per{" "}
              {subscription.id === "newbie" ? "day. Reset everyday" : "month. Can be accumulated over months"}
            </p>
          </div>
          <div className="flex">
            <StarFillIcon className="text-atherPurple-100" />
            <p className="ml-2 flex-1 font-semibold">
              Storage: {parseInt(subscription.cloudStorage) / 1024 / 1024 / 1024}GB/ user
            </p>
          </div>
        </div>
        <div className="px-4">
          <p className="font-semibold mb-2 text-whiteAlpha-600">Includes</p>
          <div className="space-y-2">
            <div className="flex items-center">
              <span className="bg-atherPurple-500 rounded-full w-4 h-4 flex items-center justify-center">
                <TickIcon width={10} height={10} />
              </span>
              <p className="ml-2 flex-1">Add max {subscription.maxMembers} members</p>
            </div>
            <div className="flex items-center">
              <span className="bg-atherPurple-500 rounded-full w-4 h-4 flex items-center justify-center">
                <TickIcon width={10} height={10} />
              </span>
              <p className="ml-2 flex-1">Team management</p>
            </div>
            <div className="flex items-center">
              <span className="bg-atherPurple-500 rounded-full w-4 h-4 flex items-center justify-center">
                <TickIcon width={10} height={10} />
              </span>
              <p className="ml-2 flex-1">Pro features:</p>
            </div>
            <div className="pl-6 space-y-1">
              <p>- {subscription.proAllRecipes ? "Advanced" : "Basic"} AI recipes</p>
              <p>- Macro Creation</p>
              <p>- Style (Quick Lora) Creation</p>
              <p>- ComfyUI on cloud</p>
              <p>- Create & publish AI recipes</p>
            </div>
          </div>
          {subscription.id !== "newbie" ? (
            <>
              <div className="flex items-center">
                <span className="bg-atherPurple-500 rounded-full w-4 h-4 flex items-center justify-center">
                  <TickIcon width={10} height={10} />
                </span>
                <p className="ml-2 flex-1">Priority support</p>
              </div>
            </>
          ) : (
            <div className="flex items-center">
              <span className="bg-atherPurple-500 rounded-full w-4 h-4 flex items-center justify-center">
                <TickIcon width={10} height={10} />
              </span>
              <p className="ml-2 flex-1">24/7 support</p>
            </div>
          )}
          <div className="flex items-center">
            <span className="bg-atherPurple-500 rounded-full w-4 h-4 flex items-center justify-center">
              <TickIcon width={10} height={10} />
            </span>
            <p className="ml-2 flex-1">Protect IP of the AI ​​models and assets you’ve created on GAIA</p>
          </div>
          {subscription.id !== "newbie" && (
            <div className="flex items-center">
              <span className="bg-atherPurple-500 rounded-full w-4 h-4 flex items-center justify-center">
                <TickIcon width={10} height={10} />
              </span>
              <p className="ml-2 flex-1">Early access to new features</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PlanItem
